/* global Munchkin */

exports.onInitialClientRender = () => {
  // Prevent glitches on viewport resize cancelling all animations / transitions during
  // the resizing, using a special CSS class. See GlobalCss.js for the class definition.
  const stopAnimationsClass = "no-animations-during-resize";
  let resizeTimer;
  window.addEventListener("resize", () => {
    document.body.classList.add(stopAnimationsClass);
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      document.body.classList.remove(stopAnimationsClass);
    }, 200);
  });
};

// Suggested here https://github.com/gatsbyjs/gatsby/issues/4014#issuecomment-370375259
// to enable the Array.from polyfill
exports.onClientEntry = () => {
  require('core-js/features/array/from');
  require('core-js/features/number/is-nan');
  require('core-js/features/object/is');
  require('core-js/features/string/repeat');
  require('element-remove');
  require('element-closest');
};

// Required to handle the Munchkin tracking code
exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (!prevLocation) return;

  const params = location.search.substr(1);
  const url = location.pathname;

  Munchkin.munchkinFunction('visitWebPage', { url, params });
}
